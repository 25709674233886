
import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import './pages/mainMenu.css';
import UserAuth from '../UserAuth';
import TutorialScreen from '../TutorialScreen';

import TutImage1 from '../../assets/images/other/Tut1.png';
import TutImage2 from '../../assets/images/other/Tut2.png';
import TutImage3 from '../../assets/images/other/Tut3.png';

import MascotImage from '../../assets/images/other/TuckerTimeMascot.png';

const MainMenu = () => {
  const navigate = useNavigate();
  const [showTutorial, setShowTutorial] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);

  const tutorialImages = [
    TutImage1,
    TutImage2,
    TutImage3,
  ];

  const goToInfoPage = () => {
    navigate('/info-page');
  };

  const learnToPlay = () => {
    setShowTutorial(true);
  };

  const goToSignIn = () => {
    setShowSignIn(true);
  };

  return (
    <div className="main-menu">
      <div className="main-menu-image-container">
        <img src={MascotImage} alt="Mascot" className="main-menu-image" />
      </div>
      <h1 className="main-title">Tucker Time</h1>
  
      <div className="button-container">
        <button onClick={goToInfoPage} className="menu-button">
          Play Game
        </button>
        <button onClick={learnToPlay} className="menu-button how-to-play-button">
          How to Play
        </button>
        <button onClick={goToSignIn} className="menu-button">
          Sign In
        </button>
      </div>
      {showTutorial && (
        <TutorialScreen
          images={tutorialImages}
          onClose={() => setShowTutorial(false)}
        />
      )}
      {showSignIn && (
        <UserAuth onClose={() => setShowSignIn(false)} />
      )}
    </div>
  );
};


export default MainMenu;

