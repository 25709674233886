import React, { useState, useEffect, useRef, useCallback } from 'react';

import Bacon from '../ingredients/Bacon';
import Toast from '../ingredients/Toast';
import Lettuce from '../ingredients/Lettuce';
import Tomato from '../ingredients/Tomato';


import recipeImg from '../../assets/images/recipes/BLT.png';
import './pages/levelTemplate.css';
import LevelTemplate from './levelTemplate';
import frying_pan from "../../assets/images/cookware/pan.svg";
import spatula from "../../assets/images/cursors/spatula.svg";
import tongs from "../../assets/images/cursors/tongs.svg";

import toasterEmpty from "../../assets/images/cookware/ToasterEmpty.png";
import toasterLeft from "../../assets/images/cookware/ToasterLeft.png";
import toasterRight from "../../assets/images/cookware/ToasterRight.png";
import toasterFull from "../../assets/images/cookware/ToasterFull.png";

const Level6 = () => {
  // Define the recipe for this level
  const recipe = {
    name: 'Toasted BLT',
    image: recipeImg,
  };

  // Define the required ingredients for this level
  const requiredIngredients = {
    Bacon: { count: 6, 
      component: <Bacon />, 
      cookingArea: 'FryingPan1', 
      intervals: [6, 2, 3], 
      timed: true
    },
    Toast: { count: 4, 
      component: <Toast />, 
      cookingArea: 'Toaster', 
      intervals: [7, 3, 4], 
      timed: true 
    },
    Tomato: { count: 4, 
      component: <Tomato />, 
      cookingArea: 'CuttingBoard', 
      intervals: null,
      timed: false
    },
    Lettuce: { count: 6, 
      component: <Lettuce />, 
      cookingArea: 'CuttingBoard', 
      intervals: null,
      timed: false
    },
  };
  
  const requiredCookware = {
    FryingPan1: {
      cookwareKey: 'FryingPan1',
      image: frying_pan,
      size: 250, 
      targetZone: 'Stove',
      objectLimit: 4, 
      innerOffsets: [50, 30],
      outerOffsets: [-60, -10],
      initialObjects: [],
      cursor: tongs,
    },
    FryingPan2: {
        cookwareKey: 'FryingPan2',
        image: frying_pan,
        size: 250, 
        targetZone: 'Stove',
        objectLimit: 4, 
        innerOffsets: [50, 30],
        outerOffsets: [180, -80], 
        initialObjects: [],
        cursor: spatula,
      },
    Toaster: {
      cookwareKey: 'Toaster',
      image: [toasterEmpty, toasterLeft, toasterRight, toasterFull],
      size: 200,
      targetZone: 'Toaster',
      objectLimit: 2,
      innerOffsets: [53, 20],
      outerOffsets: [-20, -10],
      initialObjects: [null, null]
    }
  }

  // Use the LevelTemplate component with the defined props
  return (
    <LevelTemplate
      levelNumber={6}
      recipe={recipe}
      timeLimit={35} // Time limit in seconds
      requiredIngredients={requiredIngredients}
      requiredCookware={requiredCookware}
    />
  );
};

export default Level6;
