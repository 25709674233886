import React from 'react';
import EggsBene from '../../assets/images/recipes/Eggs_benedict.png';
import './pages/levelTemplate.css';
import Avocado from '../ingredients/Avocado';
import Egg from '../ingredients/Egg';
import Toast from '../ingredients/Toast';
import LevelTemplate from './levelTemplate';
import frying_pan from "../../assets/images/cookware/pan.svg";
import toasterEmpty from "../../assets/images/cookware/ToasterEmpty.png";
import toasterLeft from "../../assets/images/cookware/ToasterLeft.png";
import toasterRight from "../../assets/images/cookware/ToasterRight.png";
import toasterFull from "../../assets/images/cookware/ToasterFull.png";
import spatula from "../../assets/images/cursors/spatula.svg";

const Level2 = () => {
  // Define the recipe for this level
  const recipe = {
    name: 'Eggs Benedict',
    image: EggsBene,
  };

  // Define the required ingredients for this level
  const requiredIngredients = {
    Toast: { count: 1, 
      component: <Toast/>, 
      cookingArea: 'Toaster', 
      intervals: [5, 3, 3], 
      timed: true 
    },
    Egg: { count: 2,
      component: <Egg/>, 
      cookingArea: 'FryingPan', 
      intervals: [3, 5, 2], 
      timed: true
    },
    Avocado: {count: 1, 
      component: <Avocado />, 
      cookingArea: 'CuttingBoard', 
      intervals: null, 
      timed: false }
  };

  const requiredCookware = {
    FryingPan: {
      cookwareKey: 'FryingPan',
      image: frying_pan,
      size: 250, 
      targetZone: 'Stove',
      objectLimit: 4, 
      innerOffsets: [50, 30],
      outerOffsets: [-60, -50],
      initialObjects: [],
      cursor: spatula,
    },
    Toaster: {
      cookwareKey: 'Toaster',
      image: [toasterEmpty, toasterLeft, toasterRight, toasterFull],
      size: 200,
      targetZone: 'Toaster',
      objectLimit: 2,
      innerOffsets: [53, 20],
      outerOffsets: [-20, -10],
      initialObjects: [null, null]
    }
  }

  // Use the LevelTemplate component with the defined props
  return (
    <LevelTemplate
      levelNumber={2}
      recipe={recipe}
      timeLimit={30} // Time limit in seconds
      requiredIngredients={requiredIngredients}
      requiredCookware={requiredCookware}
    />
  );
};

export default Level2;
