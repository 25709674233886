import React from 'react';
import Pancake from '../ingredients/Pancake';
import Strawberry from '../ingredients/Strawberry';
import Banana from '../ingredients/Banana';
import Pancakes from '../../assets/images/recipes/Pancakes.png';
import './pages/levelTemplate.css';
import LevelTemplate from './levelTemplate';
import frying_pan from "../../assets/images/cookware/pan.svg";
import spatula from "../../assets/images/cursors/spatula.svg";

const Level3 = () => {
  // Define the recipe for this level
  const recipe = {
    name: 'Pancakes',
    image: Pancakes,
  };

  // Define the required ingredients for this level
  const requiredIngredients = {
    Pancake: { count: 3, 
      component: <Pancake />, 
      cookingArea: 'FryingPan', 
      intervals: [7, 2, 2], 
      timed: true 
    },
    Banana: { count: 4, 
      component: <Banana />, 
      cookingArea: 'CuttingBoard', 
      intervals: null,
      timed: false
    },
    Strawberry: { count: 4, 
      component: <Strawberry />, 
      cookingArea: 'CuttingBoard', 
      intervals: null,
      timed: false
    },
  };

  const requiredCookware = {
    FryingPan: {
      cookwareKey: 'FryingPan',
      image: frying_pan,
      size: 250, 
      targetZone: 'Stove',
      objectLimit: 4, 
      innerOffsets: [50, 30],
      outerOffsets: [-60, -50],
      initialObjects: [],
      cursor: spatula,
    },
  }

  // Use the LevelTemplate component with the defined props
  return (
    <LevelTemplate
      levelNumber={3}
      recipe={recipe}
      timeLimit={25} // Time limit in seconds
      requiredIngredients={requiredIngredients}
      requiredCookware={requiredCookware}
    />
  );
};

export default Level3;
