import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';

const PopObject = ({ object }) => {
    const objectRef = useRef(null);
    const animationRef = useRef(null);

    useEffect(() => {
        const triggerExplosion = () => {
            if (objectRef.current) {
                animationRef.current = gsap.to(objectRef.current, {
                    scale: 3,
                    opacity: 0,
                    duration: 1,
                    ease: "power3.out",
                    onComplete: () => {
                        if (objectRef.current) {
                            objectRef.current.style.display = 'none';
                        }
                    }
                });
            }
        };

        triggerExplosion();

        // Cleanup function
        return () => {
            if (animationRef.current) {
                animationRef.current.kill();
            }
        };
    }, []);

    return (
        <div ref={objectRef}>
            {object}
        </div>
    );
};

export default PopObject;