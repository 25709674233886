import React from "react";
import Ingredient from "../Ingredient";
import bacon from "../../assets/images/ingredients/bacon.svg";

const Bacon = ({ hideable=true, onClick }) => {
    return (
        <Ingredient
            name="Bacon"
            image={[bacon]}
            hideable={hideable}
            onClick={onClick}
        />
    );
};

export default Bacon;