import React, { useState, useEffect, useRef, useCallback } from 'react';
import Corn from '../ingredients/Corn';
import Onion from '../ingredients/Onion';
import Bacon from '../ingredients/Bacon';
import Shallot from '../ingredients/Shallot';
import Carrot from '../ingredients/Carrot';
import Egg from '../ingredients/Egg';
import Rice from '../ingredients/Rice';

import Fried_Rice from '../../assets/images/recipes/FriedRice.png';
import './pages/levelTemplate.css';
import LevelTemplate from './levelTemplate';
import frying_pan from "../../assets/images/cookware/pan.svg";
import spatula from "../../assets/images/cursors/spatula.svg";
import woodenSpoon from "../../assets/images/cursors/woodenSpoon.svg";
import EmptyPot from "../../assets/images/cookware/Pot.png";
import RicePot from "../../assets/images/cookware/RicePot.png";

const Level9 = () => {
  // Define the recipe for this level
  const recipe = {
    name: 'Fried Rice',
    image: Fried_Rice,
  };

  // Define the required ingredients for this level
  const requiredIngredients = {
    Bacon: { count: 4, 
      component: <Bacon />, 
      cookingArea: 'FryingPan', 
      intervals: [7, 2, 2], 
      timed: true 
    },
    Egg: { count: 4, 
      component: <Egg />, 
      cookingArea: 'FryingPan', 
      intervals: [3, 2, 2], 
      timed: true 
    },
    Rice: { count: 1, 
      component: <Rice />, 
      cookingArea: 'RicePot', 
      intervals: [15, 4, 2], 
      timed: true 
    },
    Shallot: { count: 2, 
      component: <Shallot />, 
      cookingArea: 'CuttingBoard', 
      intervals: null,
      timed: false
    },
    Corn: { count: 2, 
      component: <Corn />, 
      cookingArea: 'CuttingBoard', 
      intervals: null,
      timed: false
    },
    Onion: { count: 2, 
      component: <Onion />, 
      cookingArea: 'CuttingBoard', 
      intervals: null,
      timed: false
    },
    Carrot: { count: 2, 
      component: <Carrot />, 
      cookingArea: 'CuttingBoard', 
      intervals: null,
      timed: false
    },
  };

  const requiredCookware = {
    FryingPan: {
      cookwareKey: 'FryingPan',
      image: frying_pan,
      size: 250, 
      targetZone: 'Stove',
      objectLimit: 4, 
      innerOffsets: [50, 30],
      outerOffsets: [-60, -10],
      initialObjects: [],
      cursor: spatula,
    },
    RicePot: {
      cookwareKey: 'RicePot',
      image: [EmptyPot, RicePot],
      size: 250, 
      targetZone: 'Stove',
      objectLimit: 1, 
      innerOffsets: [53, 30],
      outerOffsets: [180, -80], 
      initialObjects: [],
      cursor: woodenSpoon,
    },
  }

  // Use the LevelTemplate component with the defined props
  return (
    <LevelTemplate
      levelNumber={9}
      recipe={recipe}
      timeLimit={30} // Time limit in seconds
      requiredIngredients={requiredIngredients}
      requiredCookware={requiredCookware}
    />
  );
};

export default Level9;
