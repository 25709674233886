import React, { useState, useEffect, useRef, useCallback } from 'react';
import Egg from '../ingredients/Egg';
import Bacon from '../ingredients/Bacon';
import Sausage from '../ingredients/Sausage';
import Toast from '../ingredients/Toast';
import Mushroom from '../ingredients/Mushroom';
import Tomato from '../ingredients/Tomato';

import recipeImg from '../../assets/images/recipes/Full_English_Breakfast.png';
import './pages/levelTemplate.css';
import LevelTemplate from './levelTemplate';
import frying_pan from "../../assets/images/cookware/pan.svg";

import toasterEmpty from "../../assets/images/cookware/ToasterEmpty.png";
import toasterLeft from "../../assets/images/cookware/ToasterLeft.png";
import toasterRight from "../../assets/images/cookware/ToasterRight.png";
import toasterFull from "../../assets/images/cookware/ToasterFull.png";
import spatula from "../../assets/images/cursors/spatula.svg";
import tongs from "../../assets/images/cursors/tongs.svg";

const Level10 = () => {
  // Define the recipe for this level
  const recipe = {
    name: 'Huge English Breakfast',
    image: recipeImg,
  };

  // Define the required ingredients for this level
  const requiredIngredients = {
    Sausage: { count: 6, 
      component: <Sausage />, 
      cookingArea: 'FryingPan1', 
      intervals: [7, 3, 3], 
      timed: true 
    },
    Egg: { count: 6, 
        component: <Egg />, 
        cookingArea: 'FryingPan2', 
        intervals: [3, 2, 3], 
        timed: true 
      },
      Bacon: { count: 4, 
        component: <Bacon />, 
        cookingArea: 'FryingPan2', 
        intervals: [6, 2, 3], 
        timed: true 
      },
      Toast: { count: 4, 
        component: <Toast />, 
        cookingArea: 'Toaster', 
        intervals: [10, 3, 4], 
        timed: true 
      },
      Tomato: { count: 4, 
        component: <Tomato />, 
        cookingArea: 'CuttingBoard', 
        intervals: null,
        timed: false
      },
      Mushroom: { count: 6, 
        component: <Mushroom />, 
        cookingArea: 'CuttingBoard', 
        intervals: null,
        timed: false
      },
  };
  const requiredCookware = {
    FryingPan1: {
      cookwareKey: 'FryingPan1',
      image: frying_pan,
      size: 250, 
      targetZone: 'Stove',
      objectLimit: 4, 
      innerOffsets: [50, 30],
      outerOffsets: [-60, -10],
      initialObjects: [],
      cursor: tongs,
    },
    FryingPan2: {
        cookwareKey: 'FryingPan2',
        image: frying_pan,
        size: 250, 
        targetZone: 'Stove',
        objectLimit: 4, 
        innerOffsets: [50, 30],
        outerOffsets: [180, -80], 
        initialObjects: [],
        cursor: spatula,
      },
    Toaster: {
      cookwareKey: 'Toaster',
      image: [toasterEmpty, toasterLeft, toasterRight, toasterFull],
      size: 200,
      targetZone: 'Toaster',
      objectLimit: 2,
      innerOffsets: [53, 20],
      outerOffsets: [-20, -10],
      initialObjects: [null, null]
    }
  }

  // Use the LevelTemplate component with the defined props
  return (
    <LevelTemplate
      levelNumber={10}
      recipe={recipe}
      timeLimit={40} // Time limit in seconds
      requiredIngredients={requiredIngredients}
      requiredCookware={requiredCookware}
    />
  );
};

export default Level10;
