// AppContext.js
import React, { createContext, useState, useContext } from 'react'

// 创建上下文
const AppContext = createContext()

export const AppProvider = ({ children }) => {
  const [isTimerRunning, setIsTimerRunning] = useState(false)
  const [isSoundOn, setIsSoundOn] = useState(true)

  // 切换计时器状态
  const toggleTimer = () => {
    setIsTimerRunning((prev) => !prev)
  }

  const toggleSound = () => {
    setIsSoundOn(!isSoundOn)
  }

  return (
    <AppContext.Provider
      value={{ isTimerRunning, setIsTimerRunning, toggleTimer, isSoundOn, toggleSound }}
    >
      {children}
    </AppContext.Provider>
  )
}

// 创建一个自定义hook方便使用上下文
export const useAppContext = () => useContext(AppContext)
