import React, { useState, useEffect } from 'react';
import LIVE_URL from './navigation/nav';
import Loading from './Loading';

import './navigation/pages/userAuthOverlay.css';

const UserAuth = ({ onClose }) => {
    const url = LIVE_URL;
    const [name, setName] = useState('');
    const [isLogin, setIsLogin] = useState(true); // To toggle between login and sign-up
    const [statusMessage, setStatusMessage] = useState(''); // For non-intrusive confirmation
    const [isLoading, setIsLoading] = useState(false);
    const currentUserName = localStorage.getItem('name');

    // Helper function to show status messages
    const renderStatusMessage = (message) => {
        setStatusMessage(message);
        setTimeout(() => setStatusMessage(''), 3000); // Clear the message after 3 seconds
    };

    const logout = () => {
        localStorage.setItem('userID', '');
        localStorage.setItem('name', '');
        localStorage.setItem('scores', JSON.stringify({}));
        renderStatusMessage('Hope to see you again!');
        setTimeout(() => {
            onClose(); // Close the overlay after logout
        }, 2000);
    };

    useEffect(() => {
        if (isLoading) {
            setStatusMessage(<Loading />);
        }
    }, [isLoading]);

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const endpoint = isLogin ? 'login' : 'add_user';
        const successMessage = isLogin ? 'Welcome back, ' : 'Welcome, ';
        const errorMessage = isLogin ? 'Login failed: ' : 'Failed to create user: ';

        try {
            const response = await fetch(url + endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name }),
            });

            const result = await response.json();

            if (response.ok) {
                const wasPrevLogin = localStorage.getItem('userID') ? true : false;
                localStorage.setItem('userID', result.userID);
                localStorage.setItem('name', name);

                if (isLogin) {
                    localStorage.setItem('scores', JSON.stringify(result.scores));
                } else if (!wasPrevLogin && localStorage.getItem('scores')) {
                    const id = result.userID;
                    const scores = JSON.parse(localStorage.getItem('scores'));
                    try {
                        const response = await fetch(`${url}update_scores/${id}`, {
                            method: 'PUT',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(scores),  // Send the entire scores object at once
                        });
                    
                        const result = await response.json();
                        if (!response.ok) {
                            throw new Error('Failed to update user scores: ' + result.error);
                        }
                    } catch (err) {
                        console.error(errorMessage + err.message);
                    }
                }
                renderStatusMessage(successMessage + name + '!');
                setTimeout(() => {
                    onClose(); // Close the overlay after success
                }, 3000);
            } else {
                renderStatusMessage('Oops! Something went wrong');
                console.error('Error: ' + result.error);
            }
        } catch (err) {
            renderStatusMessage('Oops! Something went wrong');
            console.error(errorMessage + err.message);
        } finally {
            setIsLoading(false); 
        }
    };

    // Toggle between login and sign-up
    const toggleForm = () => {
        setIsLogin(!isLogin);
        setName(''); // Clear the input field when switching
    };

    return (
        <div className="auth-overlay">
            <div className="auth-content">
                <h2>{isLogin ? 'Login' : 'Sign Up'}</h2>
                <form onSubmit={handleSubmit} className="auth-form">
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Enter your name"
                        required
                        className="auth-input"
                    />
                    <button type="submit" className="auth-button" disabled={isLoading}>
                        {isLogin ? 'Login' : 'Sign Up'}
                    </button>
                </form>
                <p>
                    {isLogin
                        ? "Don't have an account? "
                        : 'Already have an account? '}
                    <button onClick={toggleForm} className="auth-toggle-button" disabled={isLoading}>
                        {isLogin ? 'Sign up' : 'Login'}
                    </button>
                </p>
                <div className="auth-buttons">
                    <button onClick={onClose} className="auth-close-button" disabled={isLoading}>Close</button>
                    {currentUserName && (
                        <button onClick={logout} className="auth-logout-button" disabled={isLoading}>
                            Log out as {currentUserName}?
                        </button>
                    )}
                </div>

                {/* Status Message */}
                <p className="auth-status-message">{statusMessage}</p>
            </div>
        </div>
    );
};

export default UserAuth;
