import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import PropTypes from 'prop-types';

const OneUpAnimation = ({ object }) => {
    const floatRef = useRef(null);
    const animationRef = useRef(null);

    useEffect(() => {
        const element = floatRef.current;
        if (element) {
            gsap.set(element, { y: 60, opacity: 1, scale: 1 });

            animationRef.current = gsap.to(element, {
                y: -50,
                opacity: 0,
                scale: 0.5,
                duration: 1,
                ease: "power2.out",
                onComplete: () => {
                    if (element) {
                        element.style.display = 'none';
                    }
                }
            });
        }

        return () => {
            if (animationRef.current) {
                animationRef.current.kill();
            }
        };
    }, []);

    return (
        <div ref={floatRef}>
            {object}
        </div>
    );
};

OneUpAnimation.propTypes = {
    object: PropTypes.element.isRequired,
};

export default OneUpAnimation;