import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import PropTypes from 'prop-types';
import useAudioManager from "./AudioManager";

const Cookware = forwardRef(({ 
    images, 
    size, 
    objectLimit, 
    innerOffsets = [50, 50], 
    outerOffsets = [0, 0],
    initialObjects = [],
    cursor,
}, ref) => {
    if (!objectLimit || objectLimit < 1) objectLimit = 1;

    const [objects, setObjects] = useState(initialObjects);
    const [currentImage, setCurrentImage] = useState(images[0]);

    const { playSound } = useAudioManager();

    useEffect(() => {
        // Special logic for toaster (assuming 4 images: empty, left, right, full)
        if (images.length === 4 && objectLimit === 2) {
            if (objects[0] === null && objects[1] === null) {
                setCurrentImage(images[0]); // Empty
            } else if (objects[0] !== null && objects[1] === null) {
                setCurrentImage(images[2]); // Left
            } else if (objects[0] === null && objects[1] !== null) {
                setCurrentImage(images[1]); // Right
            } else {
                setCurrentImage(images[3]); // Full
            }
        } else {
            // Default logic for other cookware
            const nonNullObjectCount = objects.filter(obj => obj !== null).length;
            setCurrentImage(images[Math.min(nonNullObjectCount, images.length - 1)]);
        }
    }, [objects, images, objectLimit]);

    // append array with new object, or replace first null with new object, or play sound to indicate full array
    const newObject = (newObject) => {
        if (objects.length < objectLimit) {
            setObjects([...objects, newObject]);
        } else if (objects.some(object => object === null)) {
            const nullIndex = objects.indexOf(null);
            const newObjects = [...objects];
            newObjects[nullIndex] = newObject;
            setObjects(newObjects);
        } else {
            playSound('cookwareFull');
        }
    };

    // replace object at index with null
    const clearObject = (index) => {
        const newObjects = [...objects];
        newObjects[index] = null;
        setObjects(newObjects);
    };

    useImperativeHandle(ref, () => ({
        newObject,
        clearObject,
        getObjects: () => objects,
        getObjectsLimit: () => objectLimit
    }));

    const renderObjects = () => {
        if (objectLimit === 1) {
            return objects[0];
        } else {
            const radius = 0.22 * size;
            const angle = 2 * Math.PI / objectLimit;

            return objects.map((object, index) => {
                const x = radius * Math.cos(angle * index);
                const y = radius * Math.sin(angle * index);

                return (
                    <div 
                        key={index}
                        style={{
                            position: 'absolute',
                            left: `calc(50% + ${x}px)`,
                            top: `calc(50% + ${y}px)`,
                            transform: 'translate(-50%, -50%)',
                        }}
                    >
                        {object}
                    </div>
                );
            });
        }
    };

    return (
        <div 
            style={{
                position: 'relative',
                width: size,
                height: size,
                left: `${outerOffsets[0]}px`,
                top: `${outerOffsets[1]}px`,
                cursor: `url(${cursor}), grab`
            }}
        >
            <img src={currentImage} style={{ width: '100%', height: '100%' }} alt="Cookware" />
            <div 
                style={{ 
                    position: 'absolute', 
                    left: `${innerOffsets[0]}%`, 
                    top: `${innerOffsets[1]}%`, 
                    transform: 'translate(-50%, -50%)' 
                }}
            >
                {renderObjects()}
            </div>
        </div>
    );
});

Cookware.propTypes = {
    images: PropTypes.arrayOf(PropTypes.string).isRequired,
    size: PropTypes.number.isRequired,
    objectLimit: PropTypes.number,
    innerOffsets: PropTypes.arrayOf(PropTypes.number).isRequired,
    outerOffsets: PropTypes.arrayOf(PropTypes.number),
    initialObjects: PropTypes.array
};

export default Cookware;
