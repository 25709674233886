import React from "react";
import Ingredient from "../Ingredient";
import whole from "../../assets/images/ingredients/strawberry.png";
import sliced from "../../assets/images/ingredients/strawberry_cut.png";

const Strawberry = ({ onClick, changeable=true, changed, hideable=true }) => {
    const handleClick = (event) => {
        event.stopPropagation();
        if (onClick) {
            onClick(event);
        }
    };
    return (
        <Ingredient
            name="Strawberry"
            image={[whole, sliced]}
            changeable={changeable}
            changed={changed}
            hideable={hideable}
            onClick={handleClick}
        />
    );
};

export default Strawberry;