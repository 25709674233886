import React, { useState } from 'react';

import './navigation/pages/tutorialScreen.css'

const TutorialScreen = ({images, onClose}) => {
    const [imageIndex, setImageIndex] = useState(0);

    const nextButtonAction = () => {
        if (imageIndex < images.length - 1) {
          setImageIndex(imageIndex + 1);
        } else {
          onClose();
        }
      };

      return (
        <div className="tutorial-overlay">
        <div className="tutorial-content">
          <img 
            src={images[imageIndex]} 
            alt={`Tutorial step ${imageIndex + 1}`}
            className="tutorial-image"
          />
          <div className="tutorial-buttons">
            <button onClick={onClose} className="tutorial-button skip-button">
              Skip Tutorial
            </button>
            <button onClick={nextButtonAction} className="tutorial-button next-button">
              {imageIndex < images.length - 1 ? 'Next' : 'Finish'}
            </button>
          </div>
        </div>
      </div>
    );
  };

export default TutorialScreen;