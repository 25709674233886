import React from "react";
import Ingredient from "../Ingredient";
import rawEgg from "../../assets/images/ingredients/rawEgg.svg";
import cookedEgg from "../../assets/images/ingredients/egg.svg";

const Egg = ({ onClick, changeable=true, changed, hideable=true }) => {
    return (
        <Ingredient
            name="Egg"
            image={[rawEgg, cookedEgg]}
            changeable={changeable}
            changed={changed}
            hideable={hideable}
            onClick={onClick}
        />
    );
};

export default Egg;