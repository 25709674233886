import React, { useState } from 'react';
import PropTypes from 'prop-types';
import OneUpAnimation from '../assets/animations/one-up';

function Ingredient({ name, image, onClick, changeable=false, changed=false, hideable=true }) {
    const [isChanged, setIsChanged] = useState(changeable && changed);
    const [hidden, setHidden] = useState(false);
    const [showAnimation, setShowAnimation] = useState(false);

    const handleClick = (event) => {
        if (onClick) {
            onClick(event);
        }
        if (changeable) {
            setIsChanged(true);
        }
        if (hideable && (!changeable || isChanged)) {
            setHidden(true);
            setShowAnimation(true);
        }
    };

    return (
        <div style={{ position: 'relative', display: 'inline-block' }}>
            {!hidden ? (
                <div onClick={handleClick}>
                    <img 
                        src={!isChanged ? image[0] : image[1]} 
                        alt={`${name}`} 
                        style={{ width: '50px', height: '50px' }} 
                    />
                    <div style={{
                        position: 'absolute',
                        bottom: '0',
                        left: '50%',
                        transform: 'translateX(-50%) translateY(50%)',
                        backgroundColor: 'rgba(125, 125, 125, 0.4)', 
                        padding: '2px',
                        borderRadius: '5px'
                    }}>
                        {name}
                    </div>
                </div>
            ) : showAnimation && (
                <OneUpAnimation image={!isChanged ? image[0] : image[1]} />
            )}
        </div>
    );
}

Ingredient.propTypes = {
    name: PropTypes.string.isRequired,
    image: PropTypes.array.isRequired,
    onClick: PropTypes.func, 
    changeable: PropTypes.bool,
    changed: PropTypes.bool,
    hideable: PropTypes.bool,
};

export default Ingredient;