import React from 'react';
import LevelTemplate from './levelTemplate';
import Egg from '../ingredients/Egg';
import Bacon from '../ingredients/Bacon';
import bacon_and_eggs_img from '../../assets/images/recipes/bacon_and_eggs.svg';
import frying_pan from "../../assets/images/cookware/pan.svg";
import spatula from "../../assets/images/cursors/spatula.svg";

const Level1 = () => {
  // Define the recipe for this level
  const recipe = {
    name: 'Bacon and Eggs',
    image: bacon_and_eggs_img,
  };

  // Define the required ingredients for this level
  const requiredIngredients = {
    Bacon: {  //each ingredient needs to be imported in at the top
      count: 2, //amount needed to complete level
      component: <Egg/>, //ingredient from imports above 
      cookingArea: 'FryingPan', //this need to match a cookware in required cookware, alternatively should be "Cuttingboard" for non timed. 
      intervals: [3, 5, 2], // [too early, perfect, too late] intervals in seconds, only needed for timed otherwise should be null
      timed: true //whether object is timed or not
    },
    Egg: { 
      count: 2,
      component: <Bacon/>, 
      cookingArea: 'FryingPan', 
      intervals: [3, 5, 2],
      timed: true 
    }
  };

  const requiredCookware = {
    FryingPan: { // should match below
      cookwareKey: 'FryingPan', //this key should match the above line name 
      image: frying_pan, //image or array of images (see level 2 for toaster example.)
      size: 250, 
      targetZone: 'Stove', //the zone the cookware will be placed in 
      objectLimit: 4, //how many objects it can hold 
      innerOffsets: [50, 30], //placement of objects within a cookware component
      outerOffsets: [-60, -50], //placement of the cookware in the play zone
      initialObjects: [], //any objects you want to be in the cookware from start (more for testing purposes)
      cursor: spatula, //what kind of cursor it should have when hovering over, pick from spatula, tongs, knife or spoon (or leave blank).
    },
  }

  // Use the LevelTemplate component with the defined props
  return (
    <LevelTemplate
      levelNumber={1}
      recipe={recipe} //recipe name and image from above
      timeLimit={30} // Time limit in seconds
      requiredIngredients={requiredIngredients} //array of ingredients and their properties
      requiredCookware={requiredCookware} //array of cookware and their properties 
    />
  );
};

export default Level1;