import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TimerBar from '../assets/animations/timerbar';
import './component-styles/timer.css'; 

const CookingTimer = ({ intervals }) => {
  const [yellowInterval, setYellowInterval] = useState(0);
  const [greenInterval, setGreenInterval] = useState(0);
  const [redInterval, setRedInterval] = useState(0);

  useEffect(() => {
    if (intervals.length === 3) {
      setYellowInterval(intervals[0]);
      setGreenInterval(intervals[1]);
      setRedInterval(intervals[2]);
    }
  }, [intervals]);

  return (
    <div className="cooking-timer-container">
        <TimerBar
            yellowInterval={yellowInterval}
            greenInterval={greenInterval}
            redInterval={redInterval}
        />
    </div>
  );
};

CookingTimer.propTypes = {
  intervals: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default CookingTimer;