import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './pages/infoScreen.css';
const InfoPage = () => {
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setButtonEnabled(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const nextButton = () => {
    if (buttonEnabled) {
      navigate('/level-select');
    }
  };

  const backButton = () => {
    navigate('/');
  };

  return (
    <div className="info-screen">
      <h1>Welcome to Tucker Time!</h1>
      <p>Why play this Game?</p>
      <p>Tucker Time is a game designed to improve your Brain Health. It targets the skills of:</p>
      <p>1) Divided attention - our ability to attend to different tasks at the same time, and respond to multiple demands of the environment</p>
      <p>2) Reaction time  - the ability to detect, process, and respond to a stimulus in a time sensitive manner.</p>
      <p>We need divided attention and good reaction times in our daily lives, for example, when driving or cooking.</p>
      <p>Start now to enjoy your cooking adventure!</p>
      <div className="button-container">
        <button 
          onClick={backButton}
          className="back-button"
        >
          Back to Main Menu
        </button>
        <button 
          onClick={nextButton} 
          disabled={!buttonEnabled}
          className={`next-button ${buttonEnabled ? 'enabled' : ''}`}
        >
          {buttonEnabled ? 'Next' : 'Please wait...'}
        </button>
      </div>
    </div>
  );
};

export default InfoPage;