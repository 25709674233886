import React from "react";
import Ingredient from "../Ingredient";
import toast from "../../assets/images/ingredients/toast.svg";

const Toast = ({ hideable=true, onClick }) => {
    return (
        <Ingredient
            name="Toast"
            image={[toast]}
            hideable={hideable}
            onClick={onClick}
        />
    );
};

export default Toast;