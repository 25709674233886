import React from 'react';
import Navigation from './components/Navigation';
import { AppProvider } from './context/AppContext';

function App() {
  return (
    <AppProvider>
      <Navigation />
    </AppProvider>
  );
}

export default App;