// PauseScreen.js
import React from 'react'
import PropTypes from 'prop-types'
import './navigation/pages/endScreens.css'

const loginPrompt = localStorage.getItem('userID') ? "Switch accounts?" : "Create an account?";

const PauseScreen = ({
  timeTaken,
  levelNumber,
  recipeName,
  onContinue,
  onClose,
  onLogin,
  onRetry,
}) => {
  return (
    <div className="overlay-screen">
      <div className="overlay-content">
        <h2 className="overlay-title">Paused!</h2>
        <p className="overlay-info">Recipe: {recipeName}</p>
        <p className="overlay-info">Time Taken: {timeTaken}</p>
        <div className="overlay-button-container">
          <button
            className="overlay-button continue-button"
            onClick={onContinue}
          >
            Continue
          </button>
          <button 
            className="overlay-button retry-button" 
            onClick={onRetry}
          >
            Retry Level
          </button>
          <button
            className="overlay-button level-select-button"
            onClick={onClose}
          >
            Level Select
          </button>
          <button
            className="overlay-button auth-button"
            onClick={onLogin}
          >
            {loginPrompt}
          </button>
        </div>
      </div>
    </div>
  )
}

PauseScreen.propTypes = {
  timeTaken: PropTypes.string.isRequired,
  levelNumber: PropTypes.number.isRequired,
  recipeName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  onRetry: PropTypes.func.isRequired,
}

export default PauseScreen
