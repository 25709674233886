import React, { useState, useEffect, useCallback  } from "react";
import CookingTimer from "./CookingTimer";
import PopObject from '../assets/animations/pop-object';
import OneUpAnimation from '../assets/animations/one-up';
import OneDownAnimation from '../assets/animations/one-down';
import useAudioManager from "./AudioManager";
import { useAppContext } from "../context/AppContext";

const TimedObject = ({ object, intervals, onClickResult, onRemove, isInvisible = false }) => {
    const { playSound, stopSound } = useAudioManager();
    const timeCreated = Date.now();
    const maxAllowedTime = intervals.reduce((acc, curr) => acc + curr, 0);
    const [timerActive, setTimerActive] = useState(true);
    const [timerStopped, setTimerStopped] = useState(false);
    const [timeElapsed, setTimeElapsed] = useState(0);
    const {isTimerRunning} = useAppContext();
    const timerExpired = timeElapsed >= maxAllowedTime

     // Function to determine if the object was clicked during the "green" interval
     const clickedWhenGreen = useCallback((time) => {
        return time >= intervals[0] && time < intervals[0] + intervals[1];
    }, [intervals]);

    const handleTimeExpired = () => {
        setTimerActive(false)
        if (onRemove) onRemove(false)
        stopSound('timerTick')
    }
    
    useEffect(() => {
        if (timeElapsed >= maxAllowedTime) {
            handleTimeExpired()
        }
    }, [timeElapsed, maxAllowedTime])

    // Effect to handle timer expiration
    useEffect(() => {
        let interval
        if (isTimerRunning && !timerExpired) {
            playSound('timerTick')
            interval = setInterval(() => {
                setTimeElapsed((prevTime) => prevTime + 0.5)
            }, 500)
        } else {
            clearInterval(interval)
        }
        
        // Clean up the timer if the component unmounts
        return () => clearTimeout(interval);
    }, [maxAllowedTime, onRemove, isTimerRunning, timerExpired]);

    // Handle click on the object
    const handleClick = () => {

        setTimerStopped(true);
        setTimerActive(false);
        stopSound('timerTick');
        
        // Determine if the click was during the "green" interval
        const clickedCorrectly = clickedWhenGreen(timeElapsed);
        console.log('Clicked correctly:', clickedCorrectly, 'Time elapsed:', timeElapsed);
        
        // Notify parent component of the click result
        if (onRemove) {
            onRemove(clickedCorrectly);
        }
    };

    const clickedAnimation = ( // set animation shown after click based on time elapsed
        <>
            {clickedWhenGreen(timeElapsed) ?
            <OneUpAnimation object={object} /> :  // set if object clicked during 'green' interval
            <OneDownAnimation object={object} />} {/* set if object clicked outside of 'green' interval */}
        </>
    );

    if (isInvisible) {
        return (
            <div 
                onClick={handleClick} 
                style={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
              {timerActive && (
                    <>
                            <div style={{ transform: 'scale(0.7)', transformOrigin: 'top center' }}>
                        <CookingTimer intervals={intervals} />
                    </div>
                        <div style={{ opacity: 0 }}>
                            {object}
                        </div>
                    </>
                )}
            </div>
        );
    }

    return (
        
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            {timerActive ? (
                // object with timer
                <>
                    <div style={{ transform: 'scale(0.6)', transformOrigin: 'top center' }}>
                        <CookingTimer intervals={intervals} />
                    </div>
                    <div onClick={handleClick}>
                        {object}
                    </div>
                </>
            ) : (
                timerExpired && !timerStopped ? (
                    // show 'pop' animation if timer expired and object was not clicked
                    <PopObject object={object} />
                ) : (
                    // show clicked animation otherwise
                    clickedAnimation
                )
            )}
        </div>
        
    );
};

export default TimedObject;