import React from "react";
import Ingredient from "../Ingredient";
import raw from "../../assets/images/ingredients/Broccoli.png";
import cooked from "../../assets/images/ingredients/Broccoli.png";

const Broccoli = ({ onClick, changeable=true, changed, hideable=true }) => {
    return (
        <Ingredient
            name="Broccoli"
            image={[raw, cooked]}
            changeable={changeable}
            changed={changed}
            hideable={hideable}
            onClick={onClick}
        />
    );
};

export default Broccoli;