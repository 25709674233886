import React from "react";
import Ingredient from "../Ingredient";
import tomatoSlice from "../../assets/images/ingredients/tomatoSlice.svg";
import tomatoWhole from "../../assets/images/ingredients/wholeTomato.svg";

const Tomato = ({ onClick, changeable=true, changed, hideable=true }) => {
    const handleClick = (event) => {
        event.stopPropagation();
        if (onClick) {
            onClick(event);
        }
    };
    return (
        <Ingredient
            name="Tomato"
            image={[tomatoWhole, tomatoSlice]}
            changeable={changeable}
            changed={changed}
            hideable={hideable}
            onClick={handleClick}
        />
    );
};

export default Tomato;