import React from "react";
import Ingredient from "../Ingredient";
import raw from "../../assets/images/ingredients/sausage.png";
import cooked from "../../assets/images/ingredients/sausage.png";

const Sausage = ({ onClick, changeable=true, changed, hideable=true }) => {
    return (
        <Ingredient
            name="Sausage"
            image={[raw, cooked]}
            changeable={changeable}
            changed={changed}
            hideable={hideable}
            onClick={onClick}
        />
    );
};

export default Sausage;