import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import LIVE_URL from './nav'
import PlayArea from '../PlayArea'
import TimedObject from '../TimedObject'
import SuccessScreen from '../SuccessScreen'
import FailureScreen from '../FailureScreen'
import PauseScreen from '../PauseScreen'
import IntroScreen from '../IntroScreen'
import UserAuth from '../UserAuth'
import kitchen from '../../assets/images/background/Kitchen4.png';
import useAudioManager from '../AudioManager';
import { useAppContext } from '../../context/AppContext'

import './pages/levelTemplate.css'

/**
 * @component LevelTemplate
 * @description The core template component for all game levels. Handles game state, timing,
 * ingredient management, scoring, and screen transitions. This component serves as the foundation
 * for building individual levels.
 * 
 * 
 * @param {number} props.levelNumber - Current level identifier
 * @param {Object} props.recipe - Recipe information including name and image
 * @param {number} props.timeLimit - Time limit for the level in seconds
 * @param {Object} props.requiredIngredients - Configuration for all ingredients needed in the level
 * @param {Object} props.requiredCookware - Configuration for all cookware needed in the level
 */

const LevelTemplate = ({
  levelNumber,
  recipe,
  timeLimit,
  requiredIngredients,
  requiredCookware
}) => {
  const url = LIVE_URL
  const navigate = useNavigate()
  const { playSound, stopSound } = useAudioManager()

  const [ingredients, setIngredients] = useState(requiredIngredients) // Array of ingredients with counts + properties
  const [choppingBoardCount, setChoppingBoardCount] = useState(0) // num of items on chopping board
  const [starScore, setStarScore] = useState(3) // player star score
  const [scoreModifier, setScoreModifier] = useState(0) // used to modify score
  const [showSuccess, setShowSuccess] = useState(false) // failure screen controller
  const [showFailure, setShowFailure] = useState(false) // success screen controller
  const [showIntro, setShowIntro] = useState(true) // intro screen controller
  const [showUserAuth, setShowUserAuth] = useState(false) // user auth screen controller
  const {isTimerRunning, setIsTimerRunning} = useAppContext() // is level timer running
  const [timeTaken, setTimeTaken] = useState(0) // elapsed time in seconds
  const [clockDisplay, setClockDisplay] = useState('00:00') //time display
  const [timeLeft, setTimeLeft] = useState(timeLimit) // initalised with timeLimit

  const playAreaRef = useRef(null)


  // Create the levelRecipe based on the recipe prop and ingredients state
  const levelRecipe = {
    name: recipe.name,
    image: recipe.image,
    ingredients: Object.entries(ingredients).map(([key, value]) => [
      React.cloneElement(value.component, {
        key: `RecipeBoard${key}`,
        changeable: false,
        hideable: false,
        onClick: () => handleIngredientClick(key),
      }),
      value.count,
    ]),
  }

  // Decrease the star score
  const decrementStarCount = () => {
    if (starScore > 0) setStarScore(starScore - 1)
  }

  // Increase the score modifier
  const incrementScoreModifier = () => {
    setScoreModifier(scoreModifier + 1)
  }

  // Update star score in database
  const updateStarScore = async () => {
    const userId = localStorage.getItem('userID')
    const level = `L${levelNumber}`
    const score = Math.max(0, starScore)
    
    const currentScores = JSON.parse(localStorage.getItem('scores')) || {};
    localStorage.setItem('scores', JSON.stringify({ ...currentScores, [level]: score }));
    
    if (!userId) {
      console.error('No user ID found, unable to update score.')
      return
    }
    try {
      // Send a PUT request to update the user's star score
      const response = await fetch(`${url}update_score/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ level, score }), // Send level and score in the body
      })

      const result = await response.json()
      if (response.ok) {
        console.log('Score updated successfully:', result.message)
      } else {
        console.error('Error updating score:', result.error)
      }
    } catch (error) {
      console.error('Failed to update score:', error)
    }
  }

  // Remove an object from the play area
  const handleRemoveObject = (objIndex) => {
    if (playAreaRef.current) {
      playAreaRef.current.removeObject(objIndex)
    }
  }

  // Add an object to the play area
  const handleAddObject = (object, key) => {
    if (playAreaRef.current) {
      playAreaRef.current.addObject(object, key)
    }
  }

  // Clear an ingredient from a cookware object
  const clearIngredientFromCookware = (index, cookwareKey) => {
    if (playAreaRef.current) {
      playAreaRef.current.handleClearObject(index, cookwareKey);
    }
  };

  // Add an ingredient to a cookware object
  const addIngredientToCookware = (ingredient, cookwareKey) => {
    if (playAreaRef.current) {
      playAreaRef.current.handleNewObject(ingredient, cookwareKey);
    }
  };

  // Handle successful completion of the level
  const handleSuccess = () => {
    updateStarScore()
    setShowSuccess(true)
  }

  // Handle failure to complete the level
  const handleFailure = () => {
    playSound('failureSound')
    setShowFailure(true)
  }

  // Start the level
  const handleStartLevel = () => {
    setShowIntro(false)
    setIsTimerRunning(true)
  }
//pause button
  const showPause = !showIntro && !isTimerRunning

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds
      .toString()
      .padStart(2, '0')}`
  }

  // Timer effect
  useEffect(() => {
    // Don't start the timer until isTimerRunning is true
    if (!isTimerRunning) return

    // if level complete, stop counting
    if (showSuccess || showFailure) return

    const interval = setInterval(() => {
      setTimeLeft((prevTimeLeft) => {
        const newTimeLeft = Math.max(prevTimeLeft - 1, 0)
        const elapsedSeconds = timeLimit - newTimeLeft

        // Format and set clockDisplay (time remaining)
        setClockDisplay(formatTime(newTimeLeft))

        // Format and set timeTaken (time elapsed)
        setTimeTaken(formatTime(elapsedSeconds))

        if (newTimeLeft <= 0) {
          clearInterval(interval)
          handleFailure()
        }
        return newTimeLeft
      })
    }, 1000)

    return () => clearInterval(interval)
  }, [isTimerRunning, showSuccess, showFailure, timeLimit])

/**
   * Handles the interaction with an ingredient, including scoring and state updates
   * @param {string} ingredientKey - Identifier for the ingredient
   * @param {number} idx - Index in the play area
   * @param {boolean} clickedCorrectly - Whether ingredient was handled at correct time
   */

  const handleIngredient = (ingredientKey, idx, clickedCorrectly) => {
    console.log(`${ingredientKey} clicked, correct:`, clickedCorrectly)

    const ingredient = ingredients[ingredientKey]

    // Handle timed ingredients
    setChoppingBoardCount((prevCount) => {
      setIngredients((prev) => {
        if (prev[ingredientKey].count <= 0) return prev
        if (ingredient.timed) {
          if (!clickedCorrectly) {
            decrementStarCount()
            playSound('unsuccessfulClick')
            // Don't decrement the count for incorrect clicks
            clearIngredientFromCookware(idx, ingredient.cookingArea)
            return prev
          }
          
          //if successful
          incrementScoreModifier()
          playSound('successfulClick')
          clearIngredientFromCookware(idx, ingredient.cookingArea)
          
          // decrement count
          return {
            ...prev,
            [ingredientKey]: {
              ...prev[ingredientKey],
              count: prev[ingredientKey].count - 1,
            },
          }
        } else {
          // Non-timed ingredients (on chopping board)
          if (prevCount === 1) return prev
          incrementScoreModifier()
          handleRemoveObject(idx)
          playSound('chopSound')
          
          // decrement count
          return {
            ...prev,
            [ingredientKey]: {
              ...prev[ingredientKey],
              count: prev[ingredientKey].count - 1,
            },
          }
        }
      })
      return ingredient.timed ? prevCount : 0
    })
  }

  // Handle clicking on an ingredient in the recipe board
  const handleIngredientClick = (ingredientKey) => {
    const ingredient = ingredients[ingredientKey]
    const currentIndex = playAreaRef.current.getObjects().length

    //get cookware capacity info
    const currentCookwareLimit = playAreaRef.current.getCookwareObjectsLimit(ingredient.cookingArea)
    const currentCookwareIndex = playAreaRef.current.getCookwareObjects(ingredient.cookingArea).length
    const firstCookwareNullIndex = playAreaRef.current.getCookwareObjects(ingredient.cookingArea).findIndex(object => object === null)

    //determine if cookware can have more ingredients 
    const cookwareLimitCheck = currentCookwareIndex < currentCookwareLimit;
    const currentUsedCookwareIndex = cookwareLimitCheck ? currentCookwareIndex : firstCookwareNullIndex;

    //spawns invisible timed objects for toast and rice as they actual cookware changes image
    if (ingredientKey === 'Toast' || ingredientKey === 'Rice') {
      const cookwareKey = ingredient.cookingArea;
      addIngredientToCookware(
        <TimedObject
          isInvisible={true}
          key={`${ingredientKey}-${currentUsedCookwareIndex}`}
          intervals={ingredient.intervals}
          object={React.cloneElement(ingredient.component, {
            key: `${ingredientKey}-${currentUsedCookwareIndex}`,
            invisible: true,
          })}
          onRemove={(clickedCorrectly) =>
            handleIngredient(ingredientKey, currentUsedCookwareIndex, clickedCorrectly)
          }
        />,
        cookwareKey
      );
    } else if (ingredient.timed) {
      // add to cookware, whatever cookware is declared with in ingredient 
      const cookwareKey = ingredient.cookingArea; 
      addIngredientToCookware(
        <TimedObject
          key={`${ingredientKey}-${currentUsedCookwareIndex}`}
          intervals={ingredient.intervals}
          object={React.cloneElement(ingredient.component, {
            key: `${ingredientKey}-${currentUsedCookwareIndex}`,
            changed: true,
          })}
          onRemove={(clickedCorrectly) =>
            handleIngredient(ingredientKey, currentUsedCookwareIndex, clickedCorrectly)
          }
        />,
        cookwareKey
      );
    } else {
      //non timed (chopping board)
      setChoppingBoardCount((prevCount) => {
        if (prevCount === 0) {
          handleAddObject(
            React.cloneElement(ingredient.component, {
              key: `${ingredientKey}-${currentIndex}`,
              onClick: () =>
                handleIngredient(ingredientKey, currentIndex, true),
              cookingArea: 'CuttingBoard',
            }),
            'CuttingBoard'
          )
          return 1
        }
        return prevCount
      })
    }
  };
  

  // Check for level completion
  useEffect(() => {
    const allIngredientsUsed = Object.values(ingredients).every(
      (ing) => ing.count === 0
    )
    if (allIngredientsUsed) {
      playSound('winSound')
      handleSuccess()
    }
  }, [ingredients, playSound])

  // Navigation handlers

  const cleanupGameState = () => {
    setIsTimerRunning(false)
    setShowSuccess(false)
    setShowFailure(false)
    setShowIntro(true)
    stopSound()
  }


   const handleClose = () => {
    cleanupGameState()
    navigate('/level-select')
  }

  const handleNext = () => {
    cleanupGameState()
    navigate(`/level${levelNumber + 1}`)
  }

  const handleRetry = () => {
    cleanupGameState()
    navigate(0)
  }

  const handleContinue = () => {
    setIsTimerRunning(true)
  }

  useEffect(() => {
    setIsTimerRunning(false)
    return () => {
      cleanupGameState()
    }
  }, [setIsTimerRunning])

  // Play area configuration
  const areas = (
    //game elements for play area
    <PlayArea 
      ref={playAreaRef}
      recipe={levelRecipe}
      imageSrc={kitchen}
      clock={clockDisplay}
      isTimerRunning={isTimerRunning}
      setIsTimerRunning={setIsTimerRunning}
      mapAreaName={'areas'}
      requiredCookware={requiredCookware}
      //zone configuration
      zones={[
        {
          shape: 'rect',
          coords: [40, 30, 60, 65],
          href: '/level-template',
          alt: 'Stove',
        },
        {
          shape: 'rect',
          coords: [16, 40, 30, 65],
          href: '/level-template',
          alt: 'CuttingBoard',
        },
        {
          shape: 'rect',
          coords: [14, 25, 34, 38],
          href: '/level-template',
          alt: 'Bench1',
        },
        {
          shape: 'rect',
          coords: [67, 25, 30, 70],
          href: '/level-template',
          alt: 'Bench2',
        },
        {
          shape: 'rect',
          coords: [75, 30, 86.5, 50],
          href: '/level-template',
          alt: 'Toaster',
        },
        {
          shape: 'rect',
          coords: [35, 75, 65, 95],
          href: '/level-template',
          alt: 'Oven',
        },
      ]}
      initialObjects={[]}
    />
  )

  return (
    <div className="level-template">
      {areas}
      {showIntro && (
        <IntroScreen
          recipeName={recipe.name}
          recipeImg={recipe.image}
          timeLimit={timeLimit}
          onStart={handleStartLevel}
          onClose={handleClose}
        />
      )}
      {showSuccess && (
        <SuccessScreen
          timeTaken={timeTaken}
          levelNumber={levelNumber}
          recipeName={recipe.name}
          onClose={handleClose}
          onRetry={handleRetry}
          onNext={handleNext}
          recipeImg={recipe.image}
          starScore={starScore}
        />
      )}
      {showPause && (
        <PauseScreen
          timeTaken={timeTaken}
          levelNumber={levelNumber}
          recipeName={recipe.name}
          onContinue={handleContinue}
          onClose={handleClose}
          onLogin={() => setShowUserAuth(true)}
          onRetry={handleRetry}
        />
      )}
      {showUserAuth && (
        <UserAuth onClose={() => setShowUserAuth(false)} />
      )}
      {showFailure && (
        <FailureScreen
          timeTaken={timeTaken}
          levelNumber={levelNumber}
          recipeName={recipe.name}
          onClose={handleClose}
          onRetry={handleRetry}
          recipeImg={recipe.image}
        />
      )}
    </div>
  )
}

export default LevelTemplate