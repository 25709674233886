/**
 * @component PlayArea
 * @description Main game area component that manages the zone layout of the kitchen, cookware/ingredient placement, 
 * functions for cookware and ingredients, and visual layout. One of these should be in each level and follow the paramters below
 * 
 * @param {Object} props.recipe - Current recipe information
 * @param {string} props.imageSrc - Background kitchen image
 * @param {string} props.clock - Current time display
 * @param {Object} props.requiredCookware - Cookware configurations
 * @param {boolean} props.isTimerRunning - Timer state
 * @param {Function} props.setIsTimerRunning - Timer state setter
 * @param {Array} props.zones - Interactive zone definitions
 * @param {Array} props.initialObjects - Starting objects in play area
 */

import React, {
  useRef, 
  createRef,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react'
import RecipeBoard from './RecipeBoard'
import PlayButton from './PlayButton'
import SoundButton from './SoundButton'
import Cookware from './Cookware';

import knife from '../assets/images/cursors/knife.svg'
import useAudioManager from './AudioManager';

const PlayArea = forwardRef(({
  recipe,
  imageSrc,
  clock,
  requiredCookware,
  isTimerRunning,
  setIsTimerRunning,
  zones,
  initialObjects,
  testing = false,
}, ref) => {
  const { playSound, stopSound } = useAudioManager()
  const cookwareRefs = useRef({}); //refs to cookware components in level
  const imgRef = useRef(null) // background image ref
  const [areas, setAreas] = useState([]) // array of the zones declared in level template 
  const [objects, setObjects] = useState(initialObjects) // all game objects 
  const boardRecipe = recipe

  // === OBJECT FUNCTIONS ===

  //moves object based on the index and index to move it to
  function moveObject(objectIndex, newKey) {
    const updatedObjects = [...objects]
    updatedObjects[objectIndex].key = newKey
    setObjects(updatedObjects)
  }
  //removes object based on index
  function removeObject(objectIndex) {
    moveObject(objectIndex, '')
  }
  //adds object to play zone
  function addObject(object, key) {
    const updatedObjects = [...objects]
    updatedObjects.push({ key, content: object })
    setObjects(updatedObjects)
  }

  // === COOKWARE FUNCTIONS ===

  //adds a new object to specified cookware 
  function handleNewObject(object, cookwareKey) {
    if (cookwareRefs.current[cookwareKey] && cookwareRefs.current[cookwareKey].current) {
      cookwareRefs.current[cookwareKey].current.newObject(object);
    }
  }

  //adds a new object based on index of a specified cookware 
  function handleClearObject(objectIndex, cookwareKey) {
    if (cookwareRefs.current[cookwareKey] && cookwareRefs.current[cookwareKey].current) {
      cookwareRefs.current[cookwareKey].current.clearObject(objectIndex);
    }
  }

  //gets the array of current objects in a specified cookware 
  function getCookwareObjects(cookwareKey) {
    if (cookwareRefs.current[cookwareKey] && cookwareRefs.current[cookwareKey].current) {
      return cookwareRefs.current[cookwareKey].current.getObjects();
    }
    return [];
  }
  // gets the max amount of objects allowed in specified cookware 
  function getCookwareObjectsLimit(cookwareKey) {
    if (cookwareRefs.current[cookwareKey] && cookwareRefs.current[cookwareKey].current) {
      return cookwareRefs.current[cookwareKey].current.getObjectsLimit();
    }
    return 0;
  }

  useImperativeHandle(ref, () => ({
    moveObject,
    removeObject,
    addObject,
    handleNewObject,
    handleClearObject,
    getObjects: () => objects,
    getCookwareObjects,
    getCookwareObjectsLimit,
  }));

  //create unique refs for each cookware in the level
  useEffect(() => {
    Object.keys(requiredCookware).forEach(cookwareKey => {
      cookwareRefs.current[cookwareKey] = createRef();
    });
  }, [requiredCookware]);

    /**
     * When there are not start time but less than 5 seconds, set the clock to alert style.
     * Alert style: font becomes to red and bigger.
     */
    const [isAlert, setIsAlert] = useState(false)
    useEffect(() => {
      setIsAlert(
        parseInt(clock.slice(3, 5)) <= 5 && parseInt(clock.slice(3, 5)) > 0
      )
    }, [clock])

    useEffect(() => {
      if (isAlert && isTimerRunning) {
        playSound('countDown')
      } else {
        stopSound('countDown')
      }
    }, [isAlert, isTimerRunning]);


    //updates areas based on screen size 
    useEffect(() => {
      const updateAreas = () => {
        if (imgRef.current) {
          const imgWidth = imgRef.current.clientWidth
          const imgHeight = imgRef.current.clientHeight

          const newAreas = zones.map((coord) => {
            const { shape, coords, href, alt } = coord
            const pixelCoords = coords.map((percent, index) => {
              return index % 2 === 0
                ? (percent / 100) * imgWidth
                : (percent / 100) * imgHeight
            })

            return { shape, coords: pixelCoords, href, alt }
          })

          setAreas(newAreas)
        }
      }

      updateAreas()
      window.addEventListener('resize', updateAreas)

      return () => {
        window.removeEventListener('resize', updateAreas)
      }
    }, [zones])
    
    return (
      <div style={{ position: 'relative', cursor:  `url(${knife}), pointer`, }}>
        <img
          ref={imgRef}
          src={imageSrc}
          alt="Example"
          style={{ display: 'block', width: '100%' }}
        />
        <div
          style={{
            position: 'absolute',
            top: '10px',
            left: '5px',
            display: 'flex',
            flexDirection: 'column',
            width: 'fit-content',
            marginTop: '1px',
            marginLeft: '5px',
          }}
        >
          <RecipeBoard recipe={boardRecipe} />
        </div>
        <div
          className={`clock-container ${isAlert ? ' alert' : ' normal'}`}
          style={{
            position: 'absolute',
            top: '10px',
            right: '90px',
            display: 'flex',
            flexDirection: 'column',
            width: 'fit-content',
            marginTop: '1px',
            marginRight: '5px',
            backgroundColor: '#8B4513',
            padding: '10px',
            borderRadius: '5px',
          }}
        >
          {clock}
        </div>
        <h1
          style={{
            position: 'absolute',
            top: '0',
            left: '50%',
            transform: 'translateX(-50%)',
            textAlign: 'center',
            width: '100%',
          }}
        >
          {recipe.name}
        </h1>
        <div
          style={{
            position: 'absolute',
            top: '10px',
            right: '30px',
          }}
        >
          <PlayButton
            isTimerRunning={isTimerRunning}
            setIsTimerRunning={setIsTimerRunning}
          />
          <SoundButton />
        </div>
         {/* Render the play zones */}
        {areas.map((area, index) => (
          <div
            key={index}
            href={area.href}
            alt={area.alt}
            style={{
              position: 'absolute',
              left: `${area.coords[0]}px`,
              top: `${area.coords[1]}px`,
              width: `${area.coords[2] - area.coords[0]}px`,
              height: `${area.coords[3] - area.coords[1]}px`,
              backgroundColor: testing
                ? 'rgba(128, 128, 128, 0.8)'
                : 'transparent',
              display: 'block',
            }}
          >
            <div
              style={{
                position: 'relative',
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              {/* Render objects in their respective zones */}
              {objects.map((object, objIndex) =>
                object.key === area.alt ? (
                  <div
                    key={objIndex}
                    onClick={object.content.props.onClick}
                    style={{ margin: '3px' }}
                  >
                    {object.content}
                  </div>
                ) : null
              )} 
              {/* Render cookware in their respective zones */}
              {Object.keys(requiredCookware).map((cookwareKey) => {
                const cookware = requiredCookware[cookwareKey];
                if (cookware.targetZone === area.alt) {
                  return (
                    <div key={cookwareKey}>
                      <Cookware
                        ref={cookwareRefs.current[cookwareKey]}
                        images={Array.isArray(cookware.image) ? cookware.image : [cookware.image]}
                        size={cookware.size}
                        objectLimit={cookware.objectLimit}
                        innerOffsets={cookware.innerOffsets}
                        outerOffsets={cookware.outerOffsets}
                        initialObjects={cookware.initialObjects}
                        cursor={cookware.cursor}
                      />
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>
        ))}
      </div>
    );
  }
);

export default PlayArea;
