import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import PropTypes from 'prop-types';

const ContinuousRotate = ({ object }) => {
    const objectRef = useRef(null);
    const animationRef = useRef(null);

    useEffect(() => {
        const triggerAnimation = () => {
            if (objectRef.current) {
                animationRef.current = gsap.to(objectRef.current, {
                    rotation: 360,
                    duration: 3,
                    repeat: -1,
                    ease: "none",
                });
            }
        };

        triggerAnimation();

        return () => {
            if (animationRef.current) {
                animationRef.current.kill();
            }
        };
    }, []);

    return (
        <div ref={objectRef}>
            {object}
        </div>
    );
};

ContinuousRotate.propTypes = {
    object: PropTypes.element.isRequired,
};

export default ContinuousRotate;
