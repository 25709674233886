import React, { useState, useEffect, useRef, useCallback } from 'react';
import Steak from '../ingredients/Steak';
import Broccoli from '../ingredients/Broccoli';
import Potato from '../ingredients/Potato';
import Carrot from '../ingredients/Carrot';

import recipeImg from '../../assets/images/recipes/Steak_and_Veggies.png';
import './pages/levelTemplate.css';
import frying_pan from "../../assets/images/cookware/pan.svg";
import spatula from "../../assets/images/cursors/spatula.svg";
import tongs from "../../assets/images/cursors/tongs.svg";
import LevelTemplate from './levelTemplate';

const Level5 = () => {
  // Define the recipe for this level
  const recipe = {
    name: 'Steak and Veggies',
    image: recipeImg,
  };

  // Define the required ingredients for this level
  const requiredIngredients = {
    Steak: { count: 3, 
      component: <Steak />, 
      cookingArea: 'FryingPan1', 
      intervals: [9, 3, 3], 
      timed: true 
    },
    Broccoli: { count: 3, 
        component: <Broccoli />, 
        cookingArea: 'FryingPan2', 
        intervals: [3, 2, 3], 
        timed: true 
      },
      Potato: { count: 2, 
        component: <Potato />, 
        cookingArea: 'CuttingBoard', 
        intervals: null,
        timed: false
      },
      Carrot: { count: 3, 
        component: <Carrot />, 
        cookingArea: 'CuttingBoard', 
        intervals: null,
        timed: false
      },
  };

  const requiredCookware = {
    FryingPan1: {
      cookwareKey: 'FryingPan1',
      image: frying_pan,
      size: 250, 
      targetZone: 'Stove',
      objectLimit: 4, 
      innerOffsets: [50, 30],
      outerOffsets: [-60, -10],
      initialObjects: [],
      cursor: tongs,
    },
    FryingPan2: {
        cookwareKey: 'FryingPan2',
        image: frying_pan,
        size: 250, 
        targetZone: 'Stove',
        objectLimit: 4, 
        innerOffsets: [50, 30],
        outerOffsets: [180, -80], 
        initialObjects: [],
        cursor: spatula,
      }
  }

  // Use the LevelTemplate component with the defined props
  return (
    <LevelTemplate
      levelNumber={5}
      recipe={recipe}
      timeLimit={25} // Time limit in seconds
      requiredIngredients={requiredIngredients}
      requiredCookware={requiredCookware}
    />
  );
};

export default Level5;
