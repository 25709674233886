import React from "react";

import ContinuousRotate from "../assets/animations/continuous-rotate";
import loading from '../assets/images/other/loading.svg';

const Loading = () => {
    return (
        <ContinuousRotate object={<img 
            src={loading} 
            alt="loading" 
            style={{ width: '50px', height: '50px', color: 'green' }} 
        />} />
    );
};

export default Loading;