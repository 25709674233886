import React, { useState, useEffect } from 'react'
import TurnOnImage from '../assets/images/soundbutton/sound-on.png'
import TurnOnHoveImage from '../assets/images/soundbutton/sound-on-hover.png'
import TurnOffImage from '../assets/images/soundbutton/sound-off.png'
import TurnOffHoverImage from '../assets/images/soundbutton/sound-off-hover.png'
import { useAppContext } from '../context/AppContext'
function SoundButton() {
  const {isSoundOn, toggleSound} = useAppContext()
  const [isHover, setIsHover] = useState(false)

  const chooseImage = (running, hover) => {
    if (running) {
      if (hover) {
        return TurnOnHoveImage
      } else {
        return TurnOnImage
      }
    } else {
      if (hover) {
        return TurnOffHoverImage
      } else {
        return TurnOffImage
      }
    }
  }


  const currentImage = chooseImage(isSoundOn, isHover)

  

  const handleMouseEnter = () => {
    setIsHover(true)
  }

  const handleMouseLeave = () => {
    setIsHover(false)
  }

  return (
    <div>
      <img
        onClick={toggleSound}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        src={currentImage}
        alt={isSoundOn ? 'On' : 'Off'}
        style={{
          cursor: 'pointer',
          width: '54px',
          height: '54px',
        }}
      />
    </div>
  )
}

export default SoundButton
