import React from 'react';
import { 
  BrowserRouter as Router, 
  Route, 
  Routes  
} from 'react-router-dom';
import MainMenu from './navigation/mainMenu';
import LevelSelect from './navigation/pagelevelSelectScreen';
import InfoPage from './navigation/infoPage';
import Level1 from './navigation/level1';
import Level2 from './navigation/level2';
import Level3 from './navigation/level3';
import Level4 from './navigation/level4';
import Level5 from './navigation/level5';
import Level6 from './navigation/level6';
import Level7 from './navigation/level7';
import Level8 from './navigation/level8';
import Level9 from './navigation/level9';
import Level10 from './navigation/level10';

import LevelTemplate from './navigation/levelTemplate';

const Navigation = () => {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<MainMenu />} />
          <Route path="/info-page" element={<InfoPage />} />
          <Route path="/level-select" element={<LevelSelect />} />
          <Route path="/level-template" element={<LevelTemplate />} />
          <Route path="/level1" element={<Level1 />} />
          <Route path="/level2" element={<Level2 />} />
          <Route path="/level3" element={<Level3 />} />
          <Route path="/level4" element={<Level4 />} />
          <Route path="/level5" element={<Level5 />} />
          <Route path="/level6" element={<Level6 />} />
          <Route path="/level7" element={<Level7 />} />
          <Route path="/level8" element={<Level8 />} />
          <Route path="/level9" element={<Level9 />} />
          <Route path="/level10" element={<Level10 />} />
          <Route path="/level11" element={<LevelSelect />} />
        </Routes>
      </Router>
    );
  };

export default Navigation;