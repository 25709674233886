import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import PropTypes from 'prop-types';
import './navigation/pages/endScreens.css';
import starFilled from '../assets/images/other/filled-star.svg';
import starEmpty from '../assets/images/other/grey-star.svg';

const SuccessScreen = ({ timeTaken, levelNumber, recipeName, onClose, onRetry, recipeImg, starScore, onNext }) => {
  const starsRef = useRef([]);

  useEffect(() => {
    // Animation for the background effect
    gsap.to('.behind-recipe-effect', {
      duration: 4,
      opacity: 1,
      scale: 1.2,
      ease: 'power2.out',
      repeat: 7,
      yoyo: true,
    });

    // Animation for the stars
    gsap.fromTo(
      starsRef.current,
      { 
        scale: 0, 
        opacity: 0, 
        rotation: -180 
      },
      { 
        scale: 1, 
        opacity: 1, 
        rotation: 0,
        duration: 0.8,
        ease: 'back.out(1.7)',
        stagger: 0.2,
      }
    );
  }, []);

  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= 3; i++) {
      stars.push(
        <img
          key={i}
          ref={el => starsRef.current[i-1] = el}
          src={i <= starScore ? starFilled : starEmpty}
          alt={`Star ${i}`}
          className="star-icon"
        />
      );
    }
    return stars;
  };

  return (
    <div className="overlay-screen">
      <div className="overlay-content">
        <h2 className="overlay-title">Level {levelNumber} Completed!</h2>
        <div className="behind-recipe-effect">
          <img src={recipeImg} alt="Success" className="success-image" />
        </div>
        <div className="star-score">{renderStars()}</div>
        <p className="overlay-info">Recipe: {recipeName}</p>
        <p className="overlay-info">Time Taken: {timeTaken}</p>
        <div className="overlay-button-container">
          <button className="overlay-button retry-button" onClick={onRetry}>Retry Level</button>
          <button className="overlay-button level-select-button" onClick={onClose}>Level Select</button>
          <button className="overlay-button level-select-button" onClick={onNext}>Next Level</button>
        </div>
      </div>
    </div>
  );
};

SuccessScreen.propTypes = {
  timeTaken: PropTypes.string.isRequired,
  levelNumber: PropTypes.number.isRequired,
  recipeName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onRetry: PropTypes.func.isRequired,
  recipeImg: PropTypes.string.isRequired,
  starScore: PropTypes.number.isRequired,
};

export default SuccessScreen;