import React, { useState } from 'react'
import PlayImage from '../assets/images/playbutton/play.png'
import PlayHoverImage from '../assets/images/playbutton/play-hover.png'
import PauseImage from '../assets/images/playbutton/pause.png'
import PauseHoverImage from '../assets/images/playbutton/pause-hover.png'

function PlayButton({ isTimerRunning, setIsTimerRunning }) {
  const [isHover, setIsHover] = useState(false)

  const chooseImage = (running, hover) => {
    if (running) {
      if (hover) {
        return PauseHoverImage
      } else {
        return PauseImage
      }
    } else {
      if (hover) {
        return PlayHoverImage
      } else {
        return PlayImage
      }
    }
  }

  const currentImage = chooseImage(isTimerRunning, isHover)

  const toggleTimerRunning = () => {
    setIsTimerRunning(!isTimerRunning)
  }

  const handleMouseEnter = () => {
    setIsHover(true)
  }

  const handleMouseLeave = () => {
    setIsHover(false)
  }

  return (
    <div>
      <img
        onClick={toggleTimerRunning}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        src={currentImage}
        alt={isTimerRunning ? 'Pause' : 'Play'}
        style={{
          cursor: 'pointer',
          width: '54px',
          height: '54px',
        }}
      />
    </div>
  )
}

export default PlayButton
