import React, { useState, useEffect, useRef, useCallback } from 'react';
import Steak from '../ingredients/Steak';
import Bun from '../ingredients/Bun';
import Tomato from '../ingredients/Tomato';
import Cheese from '../ingredients/Cheese';
import Patty from '../ingredients/Patty';

import recipeImg from '../../assets/images/recipes/BurgerX4.png';
import './pages/levelTemplate.css';
import frying_pan from "../../assets/images/cookware/pan.svg";
import spatula from "../../assets/images/cursors/spatula.svg";
import tongs from "../../assets/images/cursors/tongs.svg";
import LevelTemplate from './levelTemplate';

const Level7 = () => {
  // Define the recipe for this level
  const recipe = {
    name: 'Burgers',
    image: recipeImg,
  };

  // Define the required ingredients for this level
  const requiredIngredients = {
    Bun: { count: 4, 
      component: <Bun />, 
      cookingArea: 'FryingPan1', 
      intervals: [3, 2, 4], 
      timed: true 
    },
    Patty: { count: 4, 
        component: <Patty />, 
        cookingArea: 'FryingPan2', 
        intervals: [7, 4, 2], 
        timed: true 
      },
      Tomato: { count: 8, 
        component: <Tomato />, 
        cookingArea: 'CuttingBoard', 
        intervals: null,
        timed: false
      },
      Cheese: { count: 4, 
        component: <Cheese />, 
        cookingArea: 'CuttingBoard', 
        intervals: null,
        timed: false
      },
  };

  const requiredCookware = {
    FryingPan1: {
      cookwareKey: 'FryingPan1',
      image: frying_pan,
      size: 250, 
      targetZone: 'Stove',
      objectLimit: 4, 
      innerOffsets: [50, 30],
      outerOffsets: [-60, -10],
      initialObjects: [],
      cursor: tongs,
    },
    FryingPan2: {
        cookwareKey: 'FryingPan2',
        image: frying_pan,
        size: 250, 
        targetZone: 'Stove',
        objectLimit: 4, 
        innerOffsets: [50, 30],
        outerOffsets: [180, -80], 
        initialObjects: [],
        cursor: spatula,
      }
  }

  // Use the LevelTemplate component with the defined props
  return (
    <LevelTemplate
      levelNumber={7}
      recipe={recipe}
      timeLimit={30} // Time limit in seconds
      requiredIngredients={requiredIngredients}
      requiredCookware={requiredCookware}
    />
  );
};

export default Level7;
