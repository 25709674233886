import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import PropTypes from 'prop-types';

const OneDownObject = ({ object }) => {
    const objectRef = useRef(null);
    const animationRef = useRef(null);

    useEffect(() => {
        const triggerAnimation = () => {
            if (objectRef.current) {
                animationRef.current = gsap.to(objectRef.current, {
                    y: 100, 
                    opacity: 0,
                    duration: 1,
                    ease: "power2.in",
                    onComplete: () => {
                        if (objectRef.current) {
                            objectRef.current.style.display = 'none';
                        }
                    }
                });
            }
        };

        triggerAnimation();

        return () => {
            if (animationRef.current) {
                animationRef.current.kill();
            }
        };
    }, []);

    return (
        <div ref={objectRef}>
            {object}
        </div>
    );
};

OneDownObject.propTypes = {
    object: PropTypes.element.isRequired,
};

export default OneDownObject;